import * as React from "react";
import { Container, Typography, Box } from "@mui/material";
import styles from "./styles";

interface HeroProps {
  children?: React.ReactNode;
}

const Hero = (props: HeroProps) => {
  return (
    <Container sx={styles.container}>
      {props.children}
      <Box sx={styles.box}>
        <h1 style={styles.h1}>
          Protect your business from the ever-evolving theat with{" "}
          <span style={styles.h1Span}>UpPhish</span>
        </h1>
        {/* <Typography variant="h1" sx={styles.h1}>
          // Protect your business from the ever-evolving theat with UpPhish //{" "}
        </Typography> */}
      </Box>
    </Container>
  );
};

export default Hero;
