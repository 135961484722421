import axios from 'axios';
import { ValidationError } from 'api/types';

const postAdminLogin = async (username: string, password: string)  => {
  const url = "/api/v1/admin/auth";
  let res = null;
  try {
    res = await axios.post(url, {
      username: username,
      password: password
    });

    return res.data.token
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    return "";
  }
}

export {
  postAdminLogin,
}