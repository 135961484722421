import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import * as api from "api/admin/accounts";
import { Account } from "api/types";
import { useNavigate } from "react-router-dom";
import { useLoading } from "components/LoadingProvider";
import { Box } from "@mui/system";

const AccountsPage = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchEmailTemplates = async () => {
    setIsLoading(true);
    const res = await api.getAccounts();
    if (res.status === 200) {
      setAccounts(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // fetch list
    fetchEmailTemplates();
  }, []);

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/admin/account/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Edit
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: "accountId", headerName: "ID", width: 70 },
    { field: "accountName", headerName: "Subject", width: 200 },
    { field: "createdDate", headerName: "Sender", width: 150 },
    { field: "plan", headerName: "Plan", width: 150 },
    { field: "isActive", type: "boolean", headerName: "Active", width: 150 },
    {
      field: "Edit",
      headerName: "",
      width: 150,
      renderCell: DetailButton,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={{ paddingBottom: "1rem" }}>
            {/* <Button
              component={Link}
              to="/admin/account/new"
              variant="contained"
              color="primary"
            >
              Create
            </Button> */}
          </Box>
          <Box>
            <DataGrid
              rows={accounts}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50]}
              getRowId={(row) => row.accountUuid}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountsPage;
