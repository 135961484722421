import { color, fontFamily } from "style";

const centeredBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const h3 = {
  fontFamily: fontFamily.secondary,
  fontSize: "56px", 
}

const styles  = {
  container: {
    maxWidth: { lg: "100%"},
    display: "flex",
    height: "564px",
    backgroundColor: color.blue1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  box: {
    ...centeredBox,
    width: "800px",
    flexDirection: "column",
    paddingBottom: "32px"
  },
  box2: {
    ...centeredBox,
    flexDirection: "column",
  },
  h3a: {
    ...h3,
    color: color.grey1,
  },
  h3b: {
    ...h3,
    color: "#002C8C"
  },
  emailInput: {
    width: "320px",
    paddingBottom: "16px",
  },
  submitButton: {
    width: "320px"
  }
}

export default styles;