import React, { useState } from "react";
import { postAdminLogin } from "api/admin/auth";
import { useCookies } from "react-cookie";
import { redirect, Navigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from "@mui/material";
import { useLoading } from "components/LoadingProvider";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cookie, setCookie] = useCookies(["adminAccessToken"]);
  const { setIsLoading } = useLoading();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const token = await postAdminLogin(username, password);
    setIsLoading(false);
    if (!!!token) {
      return;
    }
    setCookie("adminAccessToken", token, { path: "/" });

    return redirect("/admin/dashboard");
  };

  return (
    <>
      {cookie.adminAccessToken && (
        <Navigate to="/admin/dashboard" replace={true} />
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 300,
          margin: "auto",
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h4" component="h1" textAlign="center">
          Sign In
        </Typography>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormControl>
        <Button type="submit" color="primary">
          Sign In
        </Button>
      </Box>
    </>
  );
};

export default AdminLogin;
