export const color = {
  white: "#fff",
  blue1: "#e6f4ff",
  blue2: "#bae0ff",
  blue3: "#91caff",
  blue4: "#69b1ff",
  blue5: "#4096ff",
  blue6: "#1677ff",
  blue7: "#0958d9",
  blue8: "#003eb3",
  blue9: "#002c8c",
  blue10: "#001d66",
  grey1: "#252525", 
}

export const fontFamily = {
  primary: '"Navigo"',
  secondary: '"DM Serif Display"',
}