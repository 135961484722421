import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Account } from "api/types";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { useCookies } from "react-cookie";
import SaveIcon from "@mui/icons-material/Save";
import { useLoading } from "components/LoadingProvider";
import { getAccount, putAccount } from "api/admin/accounts";
import { UUID } from "crypto";
import { PREMIUM_PLAN, STANDARD_PLAN, FREE_PLAN } from "const";

const AccountDetailPage = () => {
  const { accountUuid } = useParams();
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchAccount = async () => {
    if (!accountUuid) {
      return;
    }
    setIsLoading(true);

    const res = await getAccount(accountUuid);
    if (res.status === 200) {
      setAccount(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (!account) {
      console.log("No account", account);
      return;
    }

    const payload = {
      accountName: account.accountName,
      plan: account.plan,
      isActive: account.isActive,
    };
    const response = await putAccount(account.accountUuid, account);
    if (response.status === 200) {
      console.log("Saved successfully");
      navigate(0);
    }
  };

  if (!account) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={account.accountName}
              onChange={(e) =>
                setAccount({
                  ...account,
                  accountName: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              label="Created Date"
              variant="outlined"
              fullWidth
              value={account.createdDate}
              disabled
            />
            <Box>
              <FormControlLabel
                value="isActive"
                control={
                  <Checkbox
                    checked={account.isActive}
                    onChange={(e) =>
                      setAccount({
                        ...account,
                        isActive: e.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                labelPlacement="start"
              />
            </Box>
            <Box>
              <FormControlLabel
                value="plan"
                control={
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={account.plan}
                    label="Age"
                    onChange={(e) =>
                      setAccount({
                        ...account,
                        plan: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={undefined}></MenuItem>
                    <MenuItem value={FREE_PLAN}>{FREE_PLAN}</MenuItem>
                    <MenuItem value={STANDARD_PLAN}>{STANDARD_PLAN}</MenuItem>
                    <MenuItem value={PREMIUM_PLAN}>{PREMIUM_PLAN}</MenuItem>
                  </Select>
                }
                label="Plan"
                labelPlacement="start"
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={handleSave}
            style={{ marginRight: "10px" }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountDetailPage;
