import axios from 'axios';
import { SignUpOuttakeForm } from 'types/auth';

interface ValidationError {
  message: string;
  errors: Record<string, string[]>
}

const onLogin = async (username: string, password: string)  => {
  const url = "/api/v1/auth";
  let res = null;
  try {
    res = await axios.post(url, {
      username: username,
      password: password
    });

    return res.data.token
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    return "";
  }
}

const onSignUp = async (form: SignUpOuttakeForm): Promise<boolean> => {
  const url = "/api/v1/account";
  try {
    const res = await axios.post(url, form);

    return res.status === 201
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
    } else {
      console.error(error);
    }
    return false;
  }
}

const onLogout = async () => {
  const url = "/api/v1/auth";
  try {
    const res = await axios.delete(url);
    return res.status === 200;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
    } else {
      console.error(error);
    }
    return false;
  }
}

export {
  onLogin,
  onSignUp,
  onLogout
}