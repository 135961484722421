import Header from "components/Header";
import Hero from "components/Hero";
import JoinWaitlist from "components/JoinWaitlist";
const Home = () => {
  return (
    <>
      <Hero>
        <Header />
      </Hero>
      <JoinWaitlist />
    </>
  );
};

export default Home;
