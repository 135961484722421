import axios, { AxiosResponse } from 'axios';
import { ValidationError, EmailTemplate, GetEmailTemplateDetail } from 'api/types';
import { UUID } from 'crypto';
import { format } from 'path';

interface PostEmailTemplateRefreshShape {
  email_template_uuid: UUID;
}

const getEmailTemplates = async (): Promise<AxiosResponse<EmailTemplate[]>> => {
  const url = `/api/v1/email-template`;
  let res = null;
  try {
    const config = {};
    const payload = {};
    res = await axios.get(url, payload);

    return res
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
}

const getEmailTemplate = async (emailTemplateUuid: string | UUID): Promise<AxiosResponse<GetEmailTemplateDetail>> => {
  const url = `/api/v1/email-template/${emailTemplateUuid}`;
  let res = null;
  try {
    const config = {};
    const payload = {};
    res = await axios.get(url, payload);

    return res
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
}

const deleteEmailTemplate = async (emailTemplateUuid: string | UUID): Promise<AxiosResponse<EmailTemplate>> => {
  const url = `/api/v1/email-template/${emailTemplateUuid}`;
  let res = null;
  try {
    const config = {};
    const payload = {};
    res = await axios.delete(url, payload);

    return res
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
}

interface PostEmailTemplatePayload {
  image?: File,
  message?: string,
  tier?: number
  systemMessage?: string,
  userMessages?: string[]
}
const postEmailTemplate = async (payload: PostEmailTemplatePayload): Promise<AxiosResponse<EmailTemplate>> => {
  const url = "/api/v1/email-template";
  let res = null;
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // paramsSerializer: {
      //   indexes: null,
      // }
    }
    // const formattedPayload = {...payload};
    // formattedPayload.userMessages = payload.userMessages?.join('||');
    res = await axios.post(url, {...payload, userMessages: payload.userMessages?.join('||')}, config, );

    return res
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
}

const postEmailTemplateRefresh = async (emailTemplateUuid: UUID): Promise<AxiosResponse<GetEmailTemplateDetail>>  => {
  const url = `/api/v1/email-template/${emailTemplateUuid}/refresh`;
  let res = null;
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
    // const payload: PostEmailTemplateRefreshShape = {
    //   email_template_uuid: emailTemplateUuid
    // };
    res = await axios.post(url, config);

    return res.data
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
}

const putEmailTemplate = async (emailTemplate: EmailTemplate): Promise<AxiosResponse<EmailTemplate>>  => {
  const url = `/api/v1/email-template/${emailTemplate.emailTemplateUuid}`;
  let res = null;
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
    
    res = await axios.put(url, emailTemplate, config);

    return res
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status)
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
}


export {
  getEmailTemplates,
  getEmailTemplate,
  deleteEmailTemplate,
  postEmailTemplate,
  postEmailTemplateRefresh,
  putEmailTemplate
}