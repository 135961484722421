import { Icon } from "@mui/material";
import { makeStyles } from "@mui/material/styles";

interface PhishUpIconProps {
  sx?: any;
}

const PhishUpIcon = (props: PhishUpIconProps) => {
  const logoHeight = "40px";
  return (
    <Icon sx={{ ...props.sx, width: 98, height: logoHeight }}>
      <img src="media/Logo.svg" width="98px" height={logoHeight} />
    </Icon>
  );
};

export default PhishUpIcon;
