import { maxWidth } from "@mui/system";
import { color, fontFamily } from "style";

const styles  = {
  container: {
    maxWidth: { lg: "100%"},
    height: "800px",
    backgroundColor: "#191919",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundImage: `linear-gradient(to right, #191919CC 1px, transparent 1px), linear-gradient(to bottom, #191919CC 1px, transparent 1px)`,
    // backgroundSize: "40px 40px",
    backgroundImage: "url('/media/HeroBg.png')",
    backgroundImageRepeat: "no-repeat",
    backgroundSize: "cover",

  },
  box: {
    width: "800px",
  },
  h1: {
    fontFamily: fontFamily.secondary,
    height: "100%",
    color: "white",
    fontSize: "72px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "88px",
    letterSpacing: "2.16px",
  },
  h1Span: {
    padding: "0 24px",
    borderRadius: "100px",
    border: `4px solid ${color.blue6}`,
    background: "rgba(25, 25, 25, 0.80)",
  }
}

export default styles;