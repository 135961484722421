import axios from "axios";
import { Cookies } from "react-cookie";

axios.interceptors.request.use((config: any) => {
  console.log("axios interceptors");
  const cookies = new Cookies();

  // baseurl
  config.baseURL = process.env.REACT_APP_CORE_API_HOST;
  // config.paramsSerializer.indexes = null;
  // auth
  const accessToken = cookies.get("accessToken");
  const adminAccessToken = cookies.get("adminAccessToken");

  if (adminAccessToken) {
    config.headers["Authorization"] = `Bearer ${adminAccessToken}`;
  } else if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  
  return config;
});