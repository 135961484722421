import React, { useState, useEffect } from "react";
import { Button, Grid, TextField } from "@mui/material";
import {
  deleteEmailTemplate,
  getEmailTemplate,
  postEmailTemplateRefresh,
  putEmailTemplate,
} from "api/admin/template";
import { GetEmailTemplateDetail } from "api/types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import TextArea from "components/TextArea";
import { useCookies } from "react-cookie";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLoading } from "components/LoadingProvider";
import TemplateHistory from "components/TemplateHistory";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import e from "express";

const EmailTemplateDetailPage = () => {
  const { emailTemplateUuid } = useParams();
  const navigate = useNavigate();
  const [cookie, _setCookie] = useCookies(["adminAccessToken"]);
  const [emailTemplate, setEmailTemplate] = useState<
    GetEmailTemplateDetail | undefined
  >(undefined);
  const { setIsLoading } = useLoading();

  const fetchEmailTemplate = async () => {
    if (!emailTemplateUuid) {
      return;
    }
    setIsLoading(true);

    const res = await getEmailTemplate(emailTemplateUuid);
    if (res.status === 200) {
      setEmailTemplate(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // fetch list
    fetchEmailTemplate();
  }, [emailTemplateUuid]);

  if (!emailTemplate) {
    return <div>Loading...</div>;
  }

  const handleRefresh = async (_event: React.FormEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    const response = await postEmailTemplateRefresh(
      emailTemplate.emailTemplateUuid
    );
    if (response.status === 201) {
      setEmailTemplate(response.data);
    }
    setIsLoading(false);
  };

  const handleDelete = async (_event: React.FormEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    const response = await deleteEmailTemplate(emailTemplate.emailTemplateUuid);
    if (response.status === 201) {
      return navigate(`/admin/email-templates`);
    }
    setIsLoading(false);
  };

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    const response = await putEmailTemplate(emailTemplate);
    if (response.status === 200) {
      fetchEmailTemplate();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={emailTemplate.status}
                label="Age"
                onChange={(e) =>
                  setEmailTemplate({ ...emailTemplate, status: e.target.value })
                }
              >
                <MenuItem value={"draft"}>Draft</MenuItem>
                <MenuItem value={"deleted"}>Deleted</MenuItem>
                <MenuItem value={"published"}>Published</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              label="Sender Email"
              variant="outlined"
              fullWidth
              value={emailTemplate.senderEmail}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  senderEmail: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              label="Sender Name"
              variant="outlined"
              fullWidth
              value={emailTemplate.emailSenderName}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailSenderName: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              label="Subject"
              variant="outlined"
              fullWidth
              value={emailTemplate.emailSubject}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailSubject: e.target.value,
                })
              }
            />
            <TextArea
              text={emailTemplate.emailBody}
              onChange={(e: any) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailBody: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              label="Provider"
              variant="outlined"
              fullWidth
              value={emailTemplate.provider}
              onChange={(e) =>
                setEmailTemplate({
                  ...emailTemplate,
                  provider: e.target.value,
                })
              }
            />
            <Button
              variant="contained"
              onClick={handleSave}
              style={{ marginRight: "10px" }}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
            {emailTemplate.tier === 2 && (
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            )}
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          {emailTemplate.history && (
            <TemplateHistory
              history={emailTemplate.history}
              selectedTemplateId={emailTemplate.emailTemplateId}
              fetchEmailTemplate={fetchEmailTemplate}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: emailTemplate.emailBody }} />
        </Grid>
      </Grid>
    </>
  );
};

export default EmailTemplateDetailPage;
