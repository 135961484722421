const PREMIUM_PLAN = "Premium"
const STANDARD_PLAN = "Standard"
const FREE_PLAN = "Free"
const ACCOUNT_PLANS = [PREMIUM_PLAN, STANDARD_PLAN, FREE_PLAN]

export {
  PREMIUM_PLAN,
  STANDARD_PLAN,
  FREE_PLAN,
  ACCOUNT_PLANS
}