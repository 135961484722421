import React, { useState } from "react";
import { onLogin } from "api/auth";
import { useCookies } from "react-cookie";
import { redirect, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from "@mui/material";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cookie, setCookie] = useCookies(["accessToken"]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const token = await onLogin(username, password);
    if (!!!token) {
      return;
    }
    setCookie("accessToken", token, { path: "/" });

    return redirect("/dashboard");
  };

  return (
    <>
      {cookie.accessToken && <Navigate to="/dashboard" replace={true} />}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 300,
          margin: "auto",
          padding: 4,
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h4" component="h1" textAlign="center">
          Sign In
        </Typography>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input
            value={username}
            onChange={(e: any) => setUsername(e.target.value)}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            required
          />
        </FormControl>
        <Button type="submit" variant="outlined" color="primary">
          Sign In
        </Button>
        <Button
          component={Link}
          to="/signup"
          variant="outlined"
          color="primary"
        >
          Sign Up
        </Button>
      </Box>
    </>
  );
};

export default LoginForm;
