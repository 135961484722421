import { color } from "style";

const headerHeight = "72px"

export const appBarStyle = {
  maxWidth: "calc(100% - 64px)",
  height: headerHeight,
  backgroundColor: color.white,
  borderRadius: "10px",
  position: "absolute",
  top: '12px',
  left: '32px'
}

export const containerStyle = { px: 5, height: headerHeight }

export const toolbarStyle = {
  height: headerHeight,
  maxHeight: { sx: headerHeight },
  minHeight: { sx: headerHeight },
}


export const joinButtonStyle = {
  ml: "10px",
  mt: "8px",
  color: "white",
  display: "block",
  backgroundColor: color.blue6,
  borderRadius: 100,
  // py: "12px",
  // px: "24px",
  width: "168px",
  height: "48px",
  fontSize: "16px",
  textTransform: "none",
};

export const menuButtonStyle = {
  my: 2,
  color: "black",
  display: "block",
  mx: "10px",
  px: "10px",
};
