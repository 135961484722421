import * as React from "react";
import { useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useLoading } from "components/LoadingProvider";
import TextArea from "components/TextArea";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import InputMultiline from "components/InputMultiline";
import { postEmailTemplate } from "api/admin/template";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface FormState {
  freeForm: string;
}

const Tier1TemplateGenerator = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const [formState, setFormState] = useState<FormState>({
    freeForm: "",
  });

  const handleGenerate = async () => {
    setIsLoading(true);
    const response = await postEmailTemplate({
      message: formState.freeForm,
      tier: 1,
    });
    setIsLoading(false);
    if (response.status === 201) {
      return navigate(
        `/admin/email-templates/${response.data.emailTemplateUuid}`
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <h1>Tier 1 Template Generator</h1>
            <InputMultiline
              value={formState.freeForm}
              onChange={(e: any) =>
                setFormState({ ...formState, freeForm: e.target.value })
              }
            />
            <Button variant="contained" onClick={handleGenerate}>
              Generate
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Tier1TemplateGenerator;
